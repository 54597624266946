import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { StaticImage, GatsbyImage, getImage as getGatsbyImage } from 'gatsby-plugin-image';

import { getDefaultVideoSettings } from '@uq-helpers';
import { UqLegacyRequestDemoButton, UqContainerV2, UqFloatContainer, UqCustomerLogosV2, UqLayoutV2, UqAppBarV2, UqNavbarV2, UqFooterV2, UqGridRow, UqTypography, UqQuoteCarousel, UqAccordionV2, UqVideoV2, UqDialog, UqZendeskQuote } from '@uq-components';

import { Content } from '../content/v2/home';

import * as styles from './_competitor.module.scss';

const DEFAULT_ACCORDION_KEY = '0';

const VIDEO_CONFIG = {
  autoplay: true,
  settings: getDefaultVideoSettings('vimeo'),
  url: Content.hero.videoUrl,
};

UqCompetitor.propTypes = {
  data: PropTypes.shape({
    accordionImage0: PropTypes.shape({
      relativePath: PropTypes.string,
      childImageSharp: PropTypes.shape({
        gatsbyImageData: PropTypes.shape(),
      }),
    }),
    accordionImage1: PropTypes.shape({
      relativePath: PropTypes.string,
      childImageSharp: PropTypes.shape({
        gatsbyImageData: PropTypes.shape(),
      }),
    }),
    accordionImage2: PropTypes.shape({
      relativePath: PropTypes.string,
      childImageSharp: PropTypes.shape({
        gatsbyImageData: PropTypes.shape(),
      }),
    }),
  }),
  pageContext: PropTypes.shape({
    competitor: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  }),
};

export default function UqCompetitor(props) {
  const [accordionKey, setAccordionKey] = useState(0);

  const playerRef = useRef(null);
  const videoOverlayRef = useRef(null);

  const key = `accordionImage${parseInt(accordionKey)}`;
  const accordionImage = props.data[key];

  return (
    <UqLayoutV2 theme="dark">
      <UqAppBarV2>
        <UqNavbarV2 />
      </UqAppBarV2>

      <div className={styles.heroBackground}>
        <UqFloatContainer className={styles.logosContainer}>
          <section className={styles.logos}>
            <UqCustomerLogosV2 />
          </section>
        </UqFloatContainer>

        <UqContainerV2
          className={styles.heroContainer}
          fill
          overflowX
        >
          <UqGridRow className={styles.hero}>
            <div>
              <UqTypography
                as="h1"
                className={styles.headline}
              >
                {`See why users choose !-unitQ-! over ${props.pageContext.competitor.name}`}
              </UqTypography>
              <p className={styles.tagline}>
                {`Know what your users like, dislike, and want more of so you can take action
                on what's working and what's not with genAI.`}
              </p>
              <div className={styles.statsRow}>
                {renderStatistic({
                  metric: 'Increase in user retention',
                  value: '100%',
                })}
                <span className={styles.divider} />
                {renderStatistic({
                  metric: 'Boost in NPS',
                  value: '18%',
                })}
                <span className={styles.divider} />
                {renderStatistic({
                  metric: 'Faster issue resolution time',
                  value: '2x',
                })}
              </div>
              <div className={styles.heroActions}>
                <UqLegacyRequestDemoButton className="extra-wide">
                  Get a Demo — with Your Data!
                </UqLegacyRequestDemoButton>

                <UqVideoV2 config={VIDEO_CONFIG}>
                  <UqVideoV2.Trigger onClick={() => videoOverlayRef.current.open()}>
                    Watch Video
                  </UqVideoV2.Trigger>

                  <UqDialog
                    ref={videoOverlayRef}
                    onClose={() => playerRef.current.seekTo(0)}
                  >
                    <UqVideoV2.Player ref={playerRef} />
                  </UqDialog>
                </UqVideoV2>
              </div>
            </div>

            <StaticImage
              className={styles.headerImage}
              src="../images/competitor/hero-image-product-snapshot.png"
              alt="product snapshot"
              height={520}
              width={944}
              placeholder="none"
              loading="eager"
            />
          </UqGridRow>
        </UqContainerV2>
      </div>

      <UqContainerV2
        fill
        theme="light"
        className={styles.accordionContainer}
      >
        <UqGridRow className={styles.content}>
          <UqAccordionV2
            className={styles.accordion}
            onActivated={(activeKey) => setAccordionKey(activeKey)}
            defaultKey={DEFAULT_ACCORDION_KEY}
          >
            {renderExpansionPanel({
              content: `unitQ provides one platform to centralize and manage all user feedback across public and private sources.
              All noise is removed from the start, including spam, auto-replies, duplicates, bot messages and other irrelevant information.
              The cleaning process removes PII to ensure compliance, tags sensitive feedback to enable role-based
              access and standardizes the data to optimize accuracy.`,
              eventKey: '0',
              id: 'single-source',
              summary: 'Single !-source of truth-!',
            })}
            {renderExpansionPanel({
              content: `unitQ guarantees model accuracy through continuous evaluation, training, and deployment of custom ML models.
              unitQ’s supervised AI leverages a dedicated human-in-the-loop team that audits user feedback regularly and
              AI models that are re-trained regularly to drive continuous improvement,
              discover new categories, and prevent model degradation.`,
              eventKey: '1',
              id: 'supervised-ml',
              summary: 'Supervised ML with !-dedicated experts-!',
            })}
            {renderExpansionPanel({
              content: `unitQ continuously monitors your feedback categories and alerts you when an issue is spiking,
              along with the context you need to triage.
              You can perform Root Cause Analysis with one click, benchmark your product against key competitors,
              and prioritize your product roadmap based on KPIs like CSAT, NPS, and ARR.
              Additionally, unitQ seamlessly integrates with your existing tools to help you take action faster.`,
              eventKey: '2',
              id: 'teams-workflows',
              summary: 'Integrated into !-teams workflows-!',
            })}
          </UqAccordionV2>

          <div className={styles.accordionImage}>
            {/* @todo replace with figure row images */}
            <GatsbyImage
              alt="feedback categorization breakdown"
              image={getGatsbyImage(accordionImage?.childImageSharp.gatsbyImageData)}
              height={518}
              width={513}
            />
          </div>
        </UqGridRow>
      </UqContainerV2>

      <section className={styles.zendeskQuote}>
        <UqContainerV2>
          <UqZendeskQuote />
        </UqContainerV2>
      </section>

      <div className={styles.agentqBackground}>
        <UqContainerV2 overflowY fill>
          <section className={styles.agentqContainer}>
            {renderBulletin({
              subtitle:
                `Just ask a question and get an answer based on granularly categorized, AI-driven user insights.
              agentQ leverages unitQ’s best-in-class models and is engineered to prevent hallucinations,
              leading to insights you can trust. It deeply understands your business like a tenured team member,
              so It can prioritize initiatives and suggest product improvements to optimize your KPIs.
              `,
              title: 'Answer critical questions !-with agentQ-!',
            })}

            <div className={styles.agentqImage}>
              {/*  @todo [agentQ] Replace name */}
              <StaticImage
                src="../images/competitor/unitq-gpt-screenshot.png"
                alt="unitq GPT screenshot"
                placeholder="blurred"
              />
            </div>
          </section>
        </UqContainerV2>
      </div>

      <UqContainerV2
        fill
        overflowY
        className={styles.productEngagementContainer}
      >
        <UqFloatContainer className={styles.demoContainer}>
          <article className={styles.demo}>
            <section className={styles.content}>
              <span className={styles.title}>
                Want more information?
              </span>
              <p className={styles.description}>
                Book time with our team to learn why category-leading companies like
                Spotify, Pinterest, Uber, and Paypal trust unitQ to stay in sync with their users.
              </p>
              <UqLegacyRequestDemoButton>
                Get a demo - with your data!
              </UqLegacyRequestDemoButton>
            </section>
          </article>
        </UqFloatContainer>

        {renderBulletin({
          subtitle:
            `View and analyze user feedback alongside behavioral analytics signals including churn and engagement with specific features.
            By bringing behavioral cohorts into unitQ via the Amplitude integration or our API,
            you can understand in depth how different user groups behave across your product.`,
          title: 'Combine !-user feedback-! and product engagement data',
        })}
        <StaticImage
          class={styles.image}
          src="../images/competitor/product-engagement.png"
          alt="Combine user feedback and product engagement data"
        />
      </UqContainerV2>

      <UqContainerV2
        className={styles.quotesContainer}
        theme="light"
      >
        <div className={styles.content}>
          <UqQuoteCarousel quotes={Content.quoteConfig.quotes} />
        </div>
      </UqContainerV2>

      <UqFooterV2 />
    </UqLayoutV2>
  );

  function renderBulletin(config) {
    return (
      <>
        <UqTypography
          as="h2"
          className={styles.title}
        >
          {config.title}
        </UqTypography>
        <p className={classNames(styles.subtitle, 'body-3')}>
          {config.subtitle}
        </p>
      </>
    )
  }

  function renderExpansionPanel(config) {
    return (
      <UqAccordionV2.Panel
        eventKey={config.eventKey}
        id={config.id}
        summary={config.summary}
      >
        {config.content}

        <div className={styles.expansionPanelImage}>
          <GatsbyImage
            alt={config.id}
            image={getGatsbyImage(accordionImage?.childImageSharp.gatsbyImageData)}
            height={518}
            width={513}
          />
        </div>
      </UqAccordionV2.Panel>
    );
  }

  function renderStatistic(config) {
    return (
      <section className={styles.stat}>
        <h3>{config.value}</h3>
        <span className={classNames('caption-1', styles.label)}>
          {config.metric}
        </span>
      </section>
    );
  }
}

export const pageQuery = graphql`
 query {
  accordionImage0: file(name: {eq: "general-models-vs-actionable-insights"}) {
    relativePath
    childImageSharp {
      gatsbyImageData(
        formats: [AVIF, WEBP]
        placeholder: NONE
        width: 518
      )
    }
  }
  accordionImage1: file(name: {eq: "continuous-monitoring-for-new-categories"}) {
    relativePath
    childImageSharp {
      gatsbyImageData(
        formats: [AVIF, WEBP]
        placeholder: NONE
        width: 518
      )
    }
  }
  accordionImage2: file(name: {eq: "preventing-model-drift"}) {
    relativePath
    childImageSharp {
     gatsbyImageData(
        formats: [AVIF, WEBP]
        placeholder: NONE
        width: 518
      )
    }
  }
 }
`;
